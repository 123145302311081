<template>
   <section class="main cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item :to="platform === 'wechat' ?  '/activity_list/WECHAT' : '/activity_list/PUBLICACCOUNT'" > {{ platform | fitlerPlatform }}</el-breadcrumb-item>
            <el-breadcrumb-item>活动分析</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient" @click="goBack">返回</el-button>
      </el-row>
<!--         <el-row class="top">
            <h2>{{queryData.eventName}}</h2>
            <el-button class="bg-gradient" @click="goBack">返回</el-button>
         </el-row>-->
         <!-- 日期 -->
         <!--<el-row>
            <el-date-picker
                    align="center"

                    class="m-right-10 status-time"
                    value-format="yyyy-MM-dd"
                    v-model="dateRange"
                    type="daterange"
                    :start-placeholder="$t('msg.start_date')"
                    :range-separator="$t('msg.to')"
                    :end-placeholder="$t('msg.end_date')"
                    @change="getDateRange"></el-date-picker>
         </el-row>-->
         <el-row class="center">
            <el-row class="preview">
               <span class="t1">预览</span>
               <el-row class="box">
                  <el-row class="bg-box">
                     <el-row class="inapp-content">
                        <img :src="mediaPath" alt="">
                        <i class="el-icon-circle-close"></i>
                     </el-row>
                  </el-row>
               </el-row>
            </el-row>
            <el-row class="nums">
               <el-row class="vac-content user-num">
                  <p class="t1">参与用户数</p>
                  <el-row class="num">
                     <span><b>{{ queryData.joinUserCount }}</b>人</span>
                  </el-row>
               </el-row>
               <el-row class="vac-content user-rate">
                  <p class="t1">转化率</p>
                  <el-row class="progress-box">
                     <el-progress :width="100" type="circle" :percentage="conversionRate"></el-progress>
                  </el-row>
               </el-row>
               <el-row class="transform-num">
                  <p class="t1" style="margin-bottom: 15px">活动转化数（累计数）</p>
                  <el-row class="number-box">
                     <el-row class="number-item-box"><p>参与用户数<span>{{ queryData.joinUserCount }}人(0.00%)</span></p></el-row>
                     <el-row class="number-item-box"><p>送达用户数<span>{{ queryData.reachUserCount }}人(0.00%)</span></p></el-row>
                     <el-row class="number-item-box"><p>曝光用户数<span>{{ queryData.exposeUserCount }}人(0.00%)</span></p></el-row>
                     <el-row class="number-item-box"><p>点击用户数<span>{{ queryData.clickUserCount }}人(0.00%)</span></p></el-row>
                     <el-row class="number-item-box"><p>转化用户数<span>{{ queryData.convertUserCount }}人(0.00%)</span></p></el-row>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
         <el-row class="bottom" v-if="show"></el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data(){
         return {
            queryData:{
               id:'',
               eventName:'',
               joinUserCount:"",
               reachUserCount:'',
               exposeUserCount:'',
               clickUserCount:'',
               convertUserCount:'',
            },
            platform: '',
            conversionRate: 0,
            mediaPath:'',

            show: false,
            dateRange: [],
         }
      },
      mounted() {
         const activityObj = JSON.parse(sessionStorage.getItem('publicaccount') || '{}')
         for (const k in this.queryData) k in activityObj && (this.queryData[k] = activityObj[k])
         if(activityObj.hasOwnProperty('platform')) this.platform = activityObj.platform.toLowerCase()
         this.conversionRate =  Number((activityObj.reachUserCount / activityObj.joinUserCount * 100).toFixed(2)) || 0
         if(activityObj.eventWechatDTO.hasOwnProperty('mediaPath'))  this.mediaPath = window.getResourcesHost() + '/' + activityObj.eventWechatDTO.mediaPath
         this.getActivityAnalysis()
      },
      methods: {
         // getDateRange(){},
         // 获取活动分析
         getActivityAnalysis(){
            const url = urlObj.activityAnalysis + `/${this.queryData.id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  if (res.hasOwnProperty('record')) this.show = true
               }
            })
         },
         // 返回
         goBack(){
            this.$router.go(-1)
         }
      },
      filters:{
         fitlerPlatform(val){
            switch (val){
               case 'wechat':
                  return '小程序弹窗'
               case 'publicaccount':
                  return '微信群发活动'
            }
         }
      }
   }
</script>

<style scoped lang="scss">
   .main{
      //height: 100%;
      //box-sizing: border-box;
      //padding: 30px 20px 30px 40px;
      .top{
         margin: 0 0 20px 0;
         h2 { float: left;font-size: 18px;font-weight: normal }
         .el-button{ float: right }
      }
      .center{
         margin-top: 20px; min-width: 1022px;
         .preview{
            padding: 20px; box-sizing: border-box; height: 500px;
            width: 332px; background: #ffffff; float: left;
            margin-right: 20px; box-shadow: 0 0 14px #dddddd;
            .box { padding: 10px 0; height: 96%; box-sizing: border-box }
            .bg-box{
               width: 73%; height: 100%; margin: 0 auto;
               background: url("../../../../assets/create_applet_img.jpg") no-repeat;
               background-size: 100% 100%; position: relative;
               .inapp-content{
                  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
                  width: 88%; text-align: center;
                  img { width: 100% }
                  i { font-size: 28px; color: #ffffff }
               }
            }
         }
         .nums > div { padding: 15px 20px }
         .nums{
            float: left;
            .t1 { font-size: 13px }
            .vac-content{
               width: 321px; height: 193px; float: left;
               background: #ffffff; margin-bottom: 20px;
               box-shadow: 0 0 14px #dddddd;
            }
            .user-num{
               margin-right: 20px;
               .num{
                  text-align: center; line-height: 160px;
                  b { font-size: 44px; margin-right: 4px }
               }
            }
            .transform-num{
               background: #ffffff; clear: left; height: 286px;
               box-shadow: 0 0 14px #dddddd;
               .number-box{
                  font-size: 12px;
                  .number-item-box{
                     height: 45px;
                     span{
                        padding: 3px 6px; background: #9ddc87; color: #ffffff;
                        border-radius: 2px; margin-left: 16px;
                     }
                  }
               }
            }
            .user-rate{
               .progress-box{ text-align: center; padding-top: 20px }
            }
         }
      }
      .bottom{
         background: #ffffff; height: 362px; width: 1022px;
         margin: 20px 0 30px 0; box-shadow: 0 0 14px #eeeeee;
      }
   }
   @media screen and (max-width: 1366px) {
     .main {
       width: 1500px;
     }
   }
</style>
